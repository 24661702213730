import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// import Dashboard from "./components/dashboard";
import NavBar from "./view/navbar";

console.log("inasdsad");
const PrivateRoute = () => {
   console.log("inasdsad");
   const isAuth = sessionStorage.getItem("isAuthenticated");
   const isAuthenticated = localStorage.getItem("isAuthenticated");
   const roleID = localStorage.getItem("role_id");
   const category_type = localStorage.getItem("category_type");
   const pu_type = localStorage.getItem("pu_type");
   const currentUrl = window.location.href;
   const demoMode = currentUrl.includes("preview");
   console.log(roleID, demoMode, "roleID==");

   if (!isAuthenticated) {
      localStorage.clear();
   }

   if (false) {
      return <Navigate to="/login" />;
   }

   return (
      <div id="wrapper">
         <div>
            <NavBar />
         </div>
         <div id="main-content">
            <Outlet />
         </div>
         {/* </div> */}
      </div>
   );

   // < Navigate to="/kitchendisplay" />
   // <div id="wrapper">
   //    <div>
   //       <NavBar />
   //    </div>
   //    <div style={{ marginTop: "75px", marginLeft: "35px", marginRight: "35px" }}>
   //       <Routes>

   //       </Routes>
   //    </div>
   //    {/* </div> */}
   // </div>
};
//(props) => (!isAuthenticated ? <Navigate to="/login" replace={true} /> : <Component {...props} />)

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(PrivateRoute);
