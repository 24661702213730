import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Logo from "../assets/images/scantek_logo.png";
// import Avatar12 from "../assets/images/xs/avatar12.jpg";
import Avatar12 from "../assets/images/xs/profile.png";
import { Leftmenu } from "../services/leftmenu";
import { logOut } from "../store/reducer/login";
import _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TbView360Number } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { Avatar, Divider, Flex, Space, Typography } from "antd";

function NavBar() {
   const dispatch = useDispatch();
   const roleID = localStorage.getItem("role_id");
   var res = window.location.pathname;
   const currentUrl = window.location.href;
   const demoMode = currentUrl.includes("preview");
   var baseUrl = process.env.PUBLIC_URL;
   baseUrl = baseUrl.split("/");
   res = res.split("/");
   res = res.length > 0 ? res[baseUrl.length] : "/";
   res = res ? res : "/";
   //let activeKey = activeKey;
   //console.log(activeKey, "activeKey");
   const MySwal = withReactContent(Swal);

   const handleClick = () => {};
   const [menuDetails, setMenuDetails] = useState(Leftmenu);
   const [toggleMenu, setToggleMenu] = useState(true);
   const [activeMenu, setActiveMenu] = useState(null);
   let [activeKey, setActiveKey] = useState("");
   const location = useLocation(); // To listen to URL change

   const { Title } = Typography;

   console.log(menuDetails, "menuDetails");
   console.log(activeKey, "activeKey");

   const activeMenutabContainer = id => {
      var parents = document.getElementById("main-menu");
      var activeMenu = document.getElementById(id);
      let check = 0;
      for (let index = 0; index < parents.children.length; index++) {
         if (parents.children[index].id !== id) {
            parents.children[index]?.classList.remove("active");
            parents.children[index].children[1]?.classList.remove("in");
         } else {
            check = 1;
            parents.children[index]?.classList.add("active");
         }
      }
      console.log(check, "check");
      setTimeout(() => {
         console.log("in");
         // if (check == 1) {
         //     if (activeMenu) {
         //         activeMenu.classList.add("active");
         //         activeMenu.children[1].classList.add("in");
         //     }
         // }
         if (check === 1 && activeMenu) {
            activeMenu.classList.add("active");
            // Check if activeMenu has at least two children before accessing the second one
            if (activeMenu.children.length >= 2) {
               activeMenu.children[1].classList.add("in");
            } else {
               console.error("activeMenu does not have enough children.");
            }
         } else {
            activeMenu.classList.toggle("active");
            activeMenu.children[1].classList.toggle("in");
         }
      }, 100);
   };

   const onToggleMenu = () => {
      setToggleMenu(!toggleMenu);
      if (!toggleMenu) {
         document.body.classList.remove("layout-fullwidth");
      } else {
         document.body.classList.add("layout-fullwidth");
      }
   };

   // const MarkActiveSub = (active, sub) => {
   //     console.log(sub, 'sub');
   //     activeKey = sub;
   //     setactiveKey(sub);
   // }

   const logout = () => {
      MySwal.fire({
         title: "Are you sure you want to logout?",
         icon: "warning",
         showCancelButton: true,
         focusConfirm: false,
         confirmButtonText: "Yes",
         cancelButtonText: "No",
      }).then(result => {
         /* Read more about isConfirmed, isDenied below */

         if (result.isConfirmed) {
            // dispatch(logOut());
            window.location.href = "/";
         }
      });
   };

   useEffect(() => {
      if (activeKey && roleID != "3") {
         let getcontainer = menuDetails.filter(menu => {
            if (_.includes(menu.url, activeKey)) {
               return menu.container;
            }
         });
         //let get_c = _.some(menuDetails, (el) => _.includes(el.url, activeKey))

         //console.log(menuDetails, getcontainer[0].container, "menuDetails")
         if (getcontainer.length > 0) activeMenutabContainer(getcontainer[0].container);
      }
   }, [activeKey, roleID]);

   const toggleMenuFunc = container => {
      setActiveMenu(activeMenu === container ? null : container);
   };

   const MarkActiveSub = (currentKey, newKey) => {
      setActiveKey(newKey);
   };

   // Function to extract the base route from the URL
   const getBaseRouteFromUrl = () => {
      const url = window.location.pathname;
      const splitUrl = url.split("/");
      return splitUrl[1]; // This will return the base route like 'clients'
   };

   // Update active menu when the route changes
   useEffect(() => {
      const baseRoute = getBaseRouteFromUrl();
      if (baseRoute) {
         const matchedMenu = menuDetails.find(menu => _.includes(menu.url, baseRoute));
         if (matchedMenu) {
            setActiveKey(matchedMenu.url);
         }
      }
   }, [location, menuDetails]); // Listen to route changes and menu details

   return (
      <div>
         <nav className="navbar navbar-fixed-top">
            <div className="container-fluid">
               <div className="navbar-btn">
                  <button className="btn-toggle-offcanvas" onClick={onToggleMenu}>
                     <i className="lnr lnr-menu fa fa-bars"></i>
                  </button>
               </div>
               <div className="navbar-brand">
                  <Link to="/dashboard">
                     <img
                        src={document.body.classList.contains("full-dark") ? Logo : Logo}
                        alt="Horizon Logo"
                        className="img-responsive logo"
                     />
                  </Link>
               </div>
               <div className="navbar-right">
                  <div id="navbar-menu">
                     <ul className="nav navbar-nav">
                        <li className="user-info">
                           <span className="username">UserName</span>
                        </li>
                        <li>
                           <a
                              href="javascript:void(0)"
                              onClick={logout}
                              className="icon-menu"
                              title="Logout"
                           >
                              <i className="icon-login"></i>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </nav>
         <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
            <div className="sidebar-scroll">
               <Space direction="vertical" size={"small"} className="w-100 text-center py-1">
                  <Avatar className="bg-white" size={60} icon={<FaUserAlt color="#622D44" />} />
                  <Title level={5} style={{ color: "var(--primary-color)" }}>
                     User Name
                  </Title>
                  <Divider className="m-0" />
               </Space>
               <div className="tab-content p-l-0 p-r-0">
                  <div className={"tab-pane active show"} id="menu">
                     {/* <Nav id="left-sidebar-nav" className="sidebar-nav">
                               <ul id="main-menu" className="metismenu">
                                   {menuDetails != null && menuDetails.map((menu) => {
                                       return (menu?.ischild) ? (
                                           <li className="" id={menu?.container} >
                                               <a href="#!" className={(menu?.ischild) ? "has-arrow" : ""} onClick={(e) => { e.preventDefault(); activeMenutabContainer(menu?.container); }}>
                                                   <i className={menu?.icon}></i> <span>{menu?.menu}</span>
                                               </a>
                                               {(menu?.ischild) ? (
                                                   <ul className="collapse">
                                                       {menu?.child.map((submenu) => {
                                                           return (<li
                                                               className={(activeKey === submenu.url) ? "active" : ""} onClick={(e) => { e.preventDefault(); MarkActiveSub(activeKey, submenu.url); }}
                                                           >
                                                               <Link to={submenu?.url}>{submenu?.menu}</Link>
                                                           </li>)
                                                       })}
                                                   </ul>
                                               ) : ''}
                                           </li>

                                       ) : (
                                           <li className="" id={menu?.container} onClick={(e) => { e.preventDefault(); activeMenutabContainer(menu?.container); MarkActiveSub(activeKey, menu.url); }}>
                                               <Link to={menu?.url}><i className={menu?.icon}></i> <span>{menu?.menu}</span></Link>
                                           </li>
                                       )
                                   })
                                   }

                               </ul>
                           </Nav> */}
                     <Nav id="left-sidebar-nav" className="sidebar-nav">
                        <ul id="main-menu" className="metismenu">
                           {menuDetails != null &&
                              menuDetails.map(menu => {
                                 return menu.ischild ? (
                                    <li className="" id={menu.container} key={menu.container}>
                                       <a
                                          href="#!"
                                          className={menu.ischild ? "has-arrow" : ""}
                                          onClick={e => {
                                             e.preventDefault();
                                             toggleMenuFunc(menu.container);
                                          }}
                                       >
                                          <i className={menu.icon}></i>
                                          <span>{menu.menu}</span>
                                       </a>
                                       {menu.ischild && (
                                          <ul
                                             className={`collapse ${
                                                activeMenu === menu.container ? "show" : ""
                                             }`}
                                          >
                                             {menu.child.map(submenu => (
                                                <li
                                                   className={
                                                      activeKey === submenu.url ? "active" : ""
                                                   }
                                                   key={submenu.url}
                                                   onClick={e => {
                                                      e.preventDefault();
                                                      MarkActiveSub(activeKey, submenu.url);
                                                   }}
                                                >
                                                   <Link to={submenu.url}>
                                                      <i className={submenu.icon}></i>
                                                      {submenu.menu}
                                                   </Link>
                                                </li>
                                             ))}
                                          </ul>
                                       )}
                                    </li>
                                 ) : (
                                    <li
                                       className=""
                                       id={menu.container}
                                       key={menu.container}
                                       onClick={e => {
                                          e.preventDefault();
                                          toggleMenuFunc(menu.container);
                                          MarkActiveSub(activeKey, menu.url);
                                       }}
                                    >
                                       <Link to={menu.url}>
                                          <i className={menu.icon}></i>
                                          <span>{menu.menu}</span>
                                       </Link>
                                    </li>
                                 );
                              })}
                        </ul>
                     </Nav>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
export default NavBar;
