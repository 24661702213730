import { configureStore } from '@reduxjs/toolkit';
import itemCategoriesReducer from './reducer/itemCategoriesSlice';
import loginReducer from './reducer/login';
// import userReducer from './userSlice'
// import postReducer from './postsSlice'

const store = configureStore({
  reducer: {
    login: loginReducer,
    itemCategories: itemCategoriesReducer,
    // posts: postReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store;