import React from "react";
import { Button, Card } from "antd";
import { toast } from "react-toastify";
import PageHeader from "../view/pageheader";

const DashBoard = () => {
   return (
      <div>
         <PageHeader HeaderText="DashBoard" Breadcrumb={[{ name: "DashBoard" }]} backButtonUrl="" />
         <Card>
            <div className="fs-5 text-danger">Welcome !</div>
         </Card>
      </div>
   );
};

export default DashBoard;
