import React, { Fragment } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ForgotPassword from "./components/forgotpassword";
import Login from "./components/login";
import ResetPassword from "./components/resetpassword";
import PrivateRoute from "./PrivateRoute";
import MainRoutes from "./routes/MainRoutes";

function App() {
   return (
      <Fragment>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         <Router>
            <Routes>
               <Route exact path="/" element={<Login />} />
               <Route exact path="/login" element={<Login />} />
               <Route exact path="/forgotpassword" element={<ForgotPassword />} />
               <Route exact path="/resetpassword/:token" element={<ResetPassword />} />
               <Route path="/" element={<PrivateRoute />}>
                  <Route path="/*" element={<MainRoutes />} />
               </Route>
            </Routes>
         </Router>
      </Fragment>
   );
}

export default App;
