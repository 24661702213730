import React from "react";
import AdminRoutes from "./AdminRoutes";
import ManagerRoutes from "./ManagerRoutes";
import UserRoutes from "./UserRoutes";

const MainRoutes = () => {
   const user = 1;

   if (user == 1) {
      return <AdminRoutes />;
   }
   if (user == 2) {
      return <ManagerRoutes />;
   }
   if (user == 3) {
      return <UserRoutes />;
   }
};

export default MainRoutes;
