import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import DashBoard from "../components/DashBoard";
import { Card, Result, Button, Flex } from "antd";
import UserManagement from "../components/UserManagement/UserManagement";
import UserGroups from "../components/UserGroups/UserGroups";

const AdminRoutes = () => {
   return (
      <Routes>
         <Route path="/" element={<Outlet />}>
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="user-group" element={<UserGroups />} />
            <Route path="user-management" element={<UserManagement />} />
            {/* <Route
               path="*"
               element={
                  <Card>
                     <Flex
                        justify="center"
                        align="center"
                        className="text-center"
                        style={{ height: 400 }}
                     >
                        <div>
                           <h1 className="bg-light p-1 px-4" style={{ color: "#333" }}>
                              🚧 Page Under Construction 🚧
                           </h1>
                           <p
                              style={{
                                 fontSize: "1.5em",
                                 color: "#555",
                                 lineHeight: 1.5,
                                 margin: "10px 0",
                              }}
                           >
                              Please check back soon.
                           </p>
                        </div>
                     </Flex>
                  </Card>
               }
            /> */}
         </Route>
      </Routes>
   );
};

export default AdminRoutes;
