import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Space, Input, Select, Modal, Colider, Col, Divider, Row } from "antd";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const UserList = () => {
   const navigate = useNavigate();
   const [isAddModal, setIsAddModal] = useState(false);

   const [userRoles] = useState([
      {
         label: "Admin",
         value: 1,
      },
      {
         label: "Production Manager",
         value: 2,
      },
      {
         label: "Production User",
         value: 3,
      },
   ]);

   const [userGroup] = useState([
      {
         label: "Prep",
         value: 1,
      },
      {
         label: "Scan",
         value: 2,
      },
      {
         label: "OCR",
         value: 3,
      },
   ]);

   const [userStatus] = useState([
      {
         label: "Active",
         value: 1,
      },
      {
         label: "Inactive",
         value: 2,
      },
   ]);

   const userData = [
      {
         id: "1",
         name: "Alice Johnson",
         email: "alice.johnson@example.com",
         role: "Admin",
         group: "HR",
         created_by: "System",
         date_added: "2023-01-15",
         last_updated: "2023-08-01",
         status: true,
      },
      {
         id: "2",
         name: "Bob Smith",
         email: "bob.smith@example.com",
         role: "Editor",
         group: "Marketing",
         created_by: "Alice Johnson",
         date_added: "2023-02-10",
         last_updated: "2023-08-15",
         status: true,
      },
      {
         id: "3",
         name: "Charlie Brown",
         email: "charlie.brown@example.com",
         role: "Viewer",
         group: "Sales",
         created_by: "Bob Smith",
         date_added: "2023-03-05",
         last_updated: "2023-07-20",
         status: false,
      },
      {
         id: "4",
         name: "Diana Prince",
         email: "diana.prince@example.com",
         role: "Editor",
         group: "Finance",
         created_by: "System",
         date_added: "2023-04-12",
         last_updated: "2023-08-10",
         status: true,
      },
      {
         id: "5",
         name: "Ethan Hunt",
         email: "ethan.hunt@example.com",
         role: "Viewer",
         group: "Operations",
         created_by: "Diana Prince",
         date_added: "2023-05-18",
         last_updated: "2023-08-20",
         status: false,
      },
   ];

   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
         align: "center",
         sorter: true,
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         align: "center",
         sorter: true,
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
         align: "center",
         sorter: true,
      },
      {
         title: "Role",
         dataIndex: "role",
         key: "role",
         align: "center",
         sorter: true,
      },
      {
         title: "Group",
         dataIndex: "group",
         key: "group",
         align: "center",
         sorter: true,
      },
      {
         title: "Created By",
         dataIndex: "created_by",
         key: "created_by",
         align: "center",
         sorter: true,
      },
      {
         title: "Date Added",
         dataIndex: "date_added",
         key: "date_added",
         align: "center",
         sorter: true,
      },
      {
         title: "Last Updated",
         dataIndex: "last_updated",
         key: "last_updated",
         align: "center",
         sorter: true,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: data =>
            data.status ? (
               <span style={{ color: "green" }}>Active</span>
            ) : (
               <span style={{ color: "red" }}>Inactive</span>
            ),
      },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: data => (
            <Space>
               <EditOutlined className="fs-6 me-2" style={{ color: "blue" }} />
               <DeleteOutlined className="fs-6" style={{ color: "red" }} />
            </Space>
         ),
      },
   ];

   return (
      <div>
         <PageHeader
            HeaderText="User Management"
            Breadcrumb={[{ name: "User Management" }]}
            backButtonUrl=""
         />
         <Card
            title="Users List"
            extra={
               <>
                  <Button className="primary-btn" shape="round" onClick={() => setIsAddModal(true)}>
                     Add
                  </Button>
                  <Modal
                     title="Add User"
                     centered
                     className="w-50"
                     open={isAddModal}
                     onCancel={() => setIsAddModal(false)}
                     okButtonProps={{ className: "primary-btn", shape: "round" }}
                     okText="Save"
                     cancelButtonProps={{ shape: "round" }}
                  >
                     <Row gutter={[12, 6]} wrap className="p-2">
                        <Col span={12}>
                           <label htmlFor="user_name">User Name</label>
                           <span className="mx-1" style={{ color: "red" }}>
                              *
                           </span>
                           <Input />
                           {/* <span className="text-danger">Error Message</span> */}
                        </Col>
                        <Col span={12}>
                           <label htmlFor="email">Email</label>
                           <span className="mx-1" style={{ color: "red" }}>
                              *
                           </span>
                           <Input />
                           {/* <span className="text-danger">Error Message</span> */}
                        </Col>
                        <Col span={12}>
                           <label htmlFor="user_name">User Role</label>
                           <span className="mx-1" style={{ color: "red" }}>
                              *
                           </span>
                           <Select options={userRoles} className="w-100" />
                           {/* <span className="text-danger">Error Message</span> */}
                        </Col>
                        <Col span={12}>
                           <label htmlFor="user_name">User Group</label>
                           <span className="mx-1" style={{ color: "red" }}>
                              *
                           </span>
                           <Select options={userGroup} className="w-100" />
                           {/* <span className="text-danger">Error Message</span> */}
                        </Col>
                        <Col span={12}>
                           <label htmlFor="user_name">Status</label>
                           <span className="mx-1" style={{ color: "red" }}>
                              *
                           </span>
                           <Select options={userStatus} className="w-100" />
                           {/* <span className="text-danger">Error Message</span> */}
                        </Col>
                     </Row>
                  </Modal>
               </>
            }
         >
            <TableComponent columns={columns} dataSource={userData} size={"small"} />
         </Card>
      </div>
   );
};

export default UserList;
