import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

const baseUrl = getBaseEndpointUrl();

export const tryLogin = postData => {
    return Http.post(baseUrl + "auth/signin", postData);
};
export const forgotPassword = postData => {
    return Http.post(baseUrl + "auth/forgot-password", postData);
};
export const resetPassword = postData => {
    return Http.get(baseUrl + "auth/reset-password", postData);
};

export const dashboardData = (type,id,staff_admin) => {
    return Http.get(baseUrl + `dashboard/admin/${type}/${id}/${staff_admin}`, Http.getAuthToken());
};

export const staffDashboardData = (id) => {
    return Http.get(baseUrl + `dashboard/staff/${id}`, Http.getAuthToken());
};

export const dashboardJobData = (query, page, limit, sortby, order, type) => {
    return Http.get(baseUrl + `dashboard/joblist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&type=${type}`, Http.getAuthToken());
};

//Edit Staff
export const jobStatusUpdate = (postData, job_id) => {
    return Http.put(baseUrl + `job/status/` + job_id, postData, Http.getAuthToken());
};


export const staffDashboardJobData = (query, page, limit, sortby, order, type, id) => {
    return Http.get(baseUrl + `dashboard/staffjoblist?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&type=${type}&id=${id}`, Http.getAuthToken());
};

export const clientDashboardJobData = (id, job_id) => {
    return Http.get(baseUrl + `dashboard/client/${id}/${job_id}`, Http.getAuthToken());
};

export const clientDashboardReviewData = (id, job_id) => {
    return Http.get(baseUrl + `dashboard/clientreview/${id}/${job_id}`, Http.getAuthToken());
};

// <=============================== Item Categories Module =================================>

const itemCategoriesUrl = `${baseUrl}itemcategories/`;

// Get item Categories List
export const itemCategoriesList = (query, page, limit, sortby, order, id) => {
    return Http.get(
        `${itemCategoriesUrl}list?query=${query}&page=${page}&limit=${limit}&sortby=${sortby}&order=${order}&id=${id}`,
        Http.getAuthToken()
    );
};

//Create item Categories
export const createItemCategories = postData => {
    return Http.post(`${itemCategoriesUrl}create`, postData, Http.getAuthToken());
};

//Edit item Categories
export const editItemCategories = (postData, updateID) => {
    console.log(updateID, 'updateID');
    return Http.put(`${itemCategoriesUrl + updateID}`, postData, Http.getAuthToken());
};

// Delete item Categories
export const itemCategoriesDelete = (deleteID) => {
    return Http.delete(`${itemCategoriesUrl}${deleteID}`, Http.getAuthToken());
};

// <=============================== Item Categories Module =================================>



