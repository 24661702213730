import { Table } from "antd";

const TableComponent = ({
   columns,
   className = "",
   dataSource,
   onChangeDispatch,
   TotalDataCount,
   loading = false,
   size,
}) => {
   const handleOnChange = (pagination, filters, sorter, extra) => {
      const payloadData = {
         query: "",
         page: pagination.current,
         limit: pagination.pageSize,
         sortby: sorter.order ? sorter.columnKey : "",
         order: sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : "",
      };
      console.log("payloadData", payloadData);
      onChangeDispatch(payloadData);
   };

   const paginationSetup = {
      className: "me-3",
      size: "small",
      showSizeChanger: true,
      total: TotalDataCount,
      showTotal: (total, range) => {
         console.log(total, range, "total, range");
         return `Showing ${range[0]} to ${range[1]} of ${total} entries`;
      },
      // ${
      //       total !== tableData.length
      //          ? `( Filtered from ${tableData.length} total entries )`
      //          : ""
      //    }
   };

   return (
      <Table
         size={size}
         className={`border ${className ? className : ""}`}
         columns={columns}
         dataSource={dataSource}
         loading={loading}
         rowHoverable={false}
         rowKey={record => record.id}
         //  onChange={handleOnChange}
         pagination={paginationSetup}
      />
   );
};

export default TableComponent;
