import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Space, Input, Select, Modal, Colider, Col, Divider, Row } from "antd";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const UserGroups = () => {
   const navigate = useNavigate();
   const [isAddModal, setIsAddModal] = useState(false);

   const [userRoles] = useState([
      {
         label: "Admin",
         value: 1,
      },
      {
         label: "Production Manager",
         value: 2,
      },
      {
         label: "Production User",
         value: 3,
      },
   ]);

   const [userGroup] = useState([
      {
         label: "Prep",
         value: 1,
      },
      {
         label: "Scan",
         value: 2,
      },
      {
         label: "OCR",
         value: 3,
      },
   ]);

   const [userStatus] = useState([
      {
         label: "Active",
         value: 1,
      },
      {
         label: "Inactive",
         value: 2,
      },
   ]);

   const groupData = [
      {
         id: "1",
         group_name: "Preparation",
         created_by: "admin",
         date_added: "2023-01-10",
         last_updated: "2023-08-01",
         status: true,
      },
      {
         id: "2",
         group_name: "OCR",
         created_by: "admin",
         date_added: "2023-02-15",
         last_updated: "2023-08-05",
         status: true,
      },
      {
         id: "3",
         group_name: "Scanning",
         created_by: "admin",
         date_added: "2023-03-20",
         last_updated: "2023-07-20",
         status: false,
      },
      {
         id: "4",
         group_name: "Indexing",
         created_by: "admin",
         date_added: "2023-04-18",
         last_updated: "2023-07-30",
         status: true,
      },
      {
         id: "5",
         group_name: "Quality Control",
         created_by: "admin",
         date_added: "2023-05-22",
         last_updated: "2023-08-15",
         status: true,
      },
   ];

   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
         align: "center",
         sorter: true,
      },
      {
         title: "Group Name",
         dataIndex: "group_name",
         key: "group_name",
         align: "center",
         sorter: true,
      },
      {
         title: "Created By",
         dataIndex: "created_by",
         key: "created_by",
         align: "center",
         sorter: true,
      },
      {
         title: "Date Added",
         dataIndex: "date_added",
         key: "date_added",
         align: "center",
         sorter: true,
      },
      {
         title: "Last Updated",
         dataIndex: "last_updated",
         key: "last_updated",
         align: "center",
         sorter: true,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: data =>
            data.status ? (
               <span style={{ color: "green" }}>Active</span>
            ) : (
               <span style={{ color: "red" }}>Inactive</span>
            ),
      },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: data => (
            <Space>
               <EditOutlined className="fs-6 me-2" style={{ color: "blue" }} />
               <DeleteOutlined className="fs-6" style={{ color: "red" }} />
            </Space>
         ),
      },
   ];

   return (
      <div>
         <PageHeader
            HeaderText="User Groups"
            Breadcrumb={[{ name: "User Groups" }]}
            backButtonUrl=""
         />
         <Card
            title="Group List"
            extra={
               <>
                  <Button className="primary-btn" shape="round" onClick={() => setIsAddModal(true)}>
                     Add
                  </Button>
                  <Modal
                     title="Add Group"
                     centered
                     //  className="w-50"
                     open={isAddModal}
                     onCancel={() => setIsAddModal(false)}
                     okButtonProps={{ className: "primary-btn", shape: "round" }}
                     okText="Save"
                     cancelButtonProps={{ shape: "round" }}
                  >
                     <Row gutter={[12, 6]} wrap className="p-2">
                        <Col span={24}>
                           <label htmlFor="user_name">Group Name</label>
                           <span className="mx-1" style={{ color: "red" }}>
                              *
                           </span>
                           <Input />
                           {/* <span className="text-danger">Error Message</span> */}
                        </Col>
                        <Col span={24}>
                           <label htmlFor="user_name">Status</label>
                           <span className="mx-1" style={{ color: "red" }}>
                              *
                           </span>
                           <Select options={userStatus} className="w-100" />
                           {/* <span className="text-danger">Error Message</span> */}
                        </Col>
                     </Row>
                  </Modal>
               </>
            }
         >
            <TableComponent columns={columns} dataSource={groupData} size={"small"} />
         </Card>
      </div>
   );
};

export default UserGroups;
